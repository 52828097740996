import React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";

const FaqContent = () => {

    const { t, i18n } = useTranslation()
    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay text-white"
                     data-image-src="/assets/img/editing2.jpg">
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="display-1 mb-3 text-white">{t('faq')}</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb text-white">
                                    <li className="breadcrumb-item"><a href="/">{t('home')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('faq')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container  pt-15 pb-14 pb-md-16">
                    <div className="row gx-lg-8 gx-xl-12 gy-6 mb-14 mb-md-19">
                        <div className="col-lg-4">
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon btn btn-circle pe-none btn-primary me-4"><i
                                        className="uil uil-phone-volume"></i></div>
                                </div>
                                <div>
                                    <h4>24/7 Support</h4>
                                    <p className="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta
                                        gravida at eget. Fusce dapibus tellus.</p>
                                    <a href="#" className="more hover">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon btn btn-circle pe-none btn-primary me-4"><i
                                        className="uil uil-laptop-cloud"></i></div>
                                </div>
                                <div>
                                    <h4>Daily Updates</h4>
                                    <p className="mb-2">Maecenas faucibus mollis interdum. Vivamus sagittis lacus vel augue
                                        laoreet. Sed posuere consectetur.</p>
                                    <a href="#" className="more hover">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon btn btn-circle pe-none btn-primary me-4"><i
                                        className="uil uil-chart-line"></i></div>
                                </div>
                                <div>
                                    <h4>Market Research</h4>
                                    <p className="mb-2">Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent
                                        commodo cursus magna scelerisque.</p>
                                    <a href="#" className="more hover">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="display-4 mb-3 text-center">Pricing FAQ</h2>
                    <p className="lead text-center mb-10 px-md-16 px-lg-0">If you don't see an answer to your question, you can
                        send us an email from our contact form.</p>
                    <div className="row mb-14 mb-md-18">
                        <div className="col-lg-6 mb-0">
                            <div id="accordion-1" className="accordion-wrapper">
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-1-1">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-1-1" aria-expanded="false"
                                                aria-controls="accordion-collapse-1-1">Can I cancel my subscription?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-1-1" className="collapse"
                                         aria-labelledby="accordion-heading-1-1" data-bs-target="#accordion-1">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-1-2">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-1-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-1-2">Which payment methods do you accept?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-1-2" className="collapse"
                                         aria-labelledby="accordion-heading-1-2" data-bs-target="#accordion-1">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-1-3">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-1-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-1-3">How can I manage my Account?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-1-3" className="collapse"
                                         aria-labelledby="accordion-heading-1-3" data-bs-target="#accordion-1">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-1-4">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-1-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-1-4">Is my credit card information secure?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-1-4" className="collapse"
                                         aria-labelledby="accordion-heading-1-4" data-bs-target="#accordion-1">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div id="accordion-2" className="accordion-wrapper">
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-2-1">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-2-1" aria-expanded="false"
                                                aria-controls="accordion-collapse-2-1">How do I get my subscription receipt?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-2-1" className="collapse"
                                         aria-labelledby="accordion-heading-2-1" data-bs-target="#accordion-2">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-2-2">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-2-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-2-2">Are there any discounts for people in
                                            need?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-2-2" className="collapse"
                                         aria-labelledby="accordion-heading-2-2" data-bs-target="#accordion-2">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-2-3">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-2-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-2-3">Do you offer a free trial edit?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-2-3" className="collapse"
                                         aria-labelledby="accordion-heading-2-3" data-bs-target="#accordion-2">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <div className="card-header" id="accordion-heading-2-4">
                                        <button className="collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#accordion-collapse-2-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-2-4">How do I reset my Account password?
                                        </button>
                                    </div>
                                    <div id="accordion-collapse-2-4" className="collapse"
                                         aria-labelledby="accordion-heading-2-4" data-bs-target="#accordion-2">
                                        <div className="card-body">
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                                                fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet
                                                fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                                mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default FaqContent;
